$(window).on('load', function () {
  animate();
});
function animate() {
  window.sr = ScrollReveal({});
  sr.reveal('.c-section__message', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__characters', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 1250, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__date', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 2000, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__people', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "ease" });

  sr.reveal('.c-intro__message', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__body:nth-of-type(1)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 1250, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__body:nth-of-type(2)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 2000, opacity: 0, easing: "ease" });
  sr.reveal('.c-section__body:nth-of-type(3)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "ease", mobile: false });
  sr.reveal('.c-section__body:nth-of-type(4)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 1250, opacity: 0, easing: "ease", mobile: false });
  sr.reveal('.c-section__body:nth-of-type(3)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 2750, opacity: 0, easing: "ease", desktop: false });
  sr.reveal('.c-section__body:nth-of-type(4)', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "ease", desktop: false });
};
